import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw
} from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboardView",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/IndexView.vue"),
    children: [
      {
        path: "dashboardView",
        name: "dashboardView",
        component: () =>
          import(
            /* webpackChunkName: "dashboardView"  */ "@/views/DashboardView.vue"
          )
      },
      {
        path: "dashboardInfoView",
        name: "dashboardInfoView",
        component: () =>
          import(
            /* webpackChunkName: "dashboardInfoView"  */ "@/views/DashboardInfoView.vue"
          )
      }
      // {
      //   path: "dashboardMobileView",
      //   name: "dashboardMobileView",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "DashboardMobileView"  */ "@/views/DashboardMobileView.vue"
      //     )
      // }
    ]
  },

  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/LoginView.vue")
  },
  {
    path: "/registerView",
    name: "registerView",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "@/views/RegisterView/index.vue"
      )
  }
];

const router = createRouter({
  history: createWebHashHistory(), //createWebHistory(process.env.BASE_URL),
  routes
});
// export function resetRouter() {
//   let routers = router.getRoutes();
//   routers.map((it) => {
//     router.removeRoute(it.name!);
//   });
// }

export default router;
